import {
  ENotificationType,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { useCreateWorkshopNotesMutation } from 'redux/apiSlice/workshops.slice';
import NoteCard from '../components/NoteCard';

const AddNote = ({
  workshopId,
  noteId,
}: {
  workshopId: string;
  noteId?: string;
}) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.notes.add',
  });
  const { showToastNotification } = useShowToastNotification();
  const [createWorkshopNotes, { isLoading, isError }] =
    useCreateWorkshopNotesMutation();

  const onSubmit = async (value: string) => {
    try {
      await createWorkshopNotes({
        workshopId,
        note: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <NoteCard
      workshopId={workshopId}
      noteId={noteId}
      onEdit={onSubmit}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export default AddNote;
