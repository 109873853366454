import { Card, Col, Row, useTranslation } from '@packages/utils';
import EditableReachTextEditor from 'components/Base/EditableFields/EditableReachTextEditor';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import RemoveNote from '../../RemoveNote';

const NoteCard = ({
  workshopId,
  noteId,
  note,
  onEdit,
  isError,
  isLoading,
  readonly = false,
}: {
  workshopId: string;
  noteId?: string;
  note?: string;
  onEdit: (value: string) => void;
  isError: boolean;
  isLoading: boolean;
  readonly?: boolean;
}) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.notes.edit.note',
  });

  return (
    <Card className="mb-4">
      <Col className="ml-1">
        <Row justify="space-between">
          <Col>
            <InplaceEditLabels label={t('INPLACE_EDIT_LABEL')} />
          </Col>
          {noteId && !readonly && (
            <Col>
              <RemoveNote workshopId={workshopId} noteId={noteId} />
            </Col>
          )}
        </Row>
      </Col>
      <Col>
        <EditableReachTextEditor
          title={note || ''}
          fitContent={false}
          onEdit={onEdit}
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
          readonly={readonly}
          isError={isError}
          isLoading={isLoading}
          required
        />
      </Col>
    </Card>
  );
};

export default NoteCard;
