import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from '@packages/utils';
import { useGetWorkshopByIdQuery } from 'redux/apiSlice/workshops.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { RoutesPaths } from 'routes/Routes.types';
import WorkshopStatus from 'components/Shared/Statuses/WorkshopStatus';
import PageWrapper from 'components/Base/PageWrapper';
import { TabsProps } from 'components/Base/Tabs/Tabs.types';
import { Tabs } from 'components/Base/Tabs';
import { EWorkshopStatus } from 'data/types/workshops.types';
import { TRtkErrorType } from 'data/types/generic.types';
import { TBackButtonState } from 'components/Base/PageWrapper/PageWrapper.types';
import useKeepTabsInUrl from 'hooks/useKeepTabsInUrl';
import Actions from './Actions';
import DetailsTab from './DetailsTab';
import WaitListTab from './WaitlistTab';
import { EWorkShopDetailsTabsUrl } from './WorkShopDetails.types';
import ChildCareTab from './ChildCareTab';
import QuestionTab from './QuestionTab';
import RegistrantsTab from './RegistrantsTab';
import AttendanceTab from './AttendanceTab';
import NotesTab from './NotesTab';

const WorkShopDetails = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops',
  });
  const { showToastNotification } = useShowToastNotification();
  const params = useParams();
  const location = useLocation();
  const goBackState = (location.state as TBackButtonState) || {
    backUrl: `/app/${RoutesPaths.WORKSHOP}`,
    backButtonSuffix: t('list.TITLE'),
  };
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const { id } = params as { id: string };
  const { data, isLoading, isFetching, error } = useGetWorkshopByIdQuery({
    id,
  });

  useEffect(() => {
    if (location.search.includes('duplicated')) {
      showToastNotification({
        message: t('details.actions.duplicate.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      searchParams.delete('workshopAction');
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [location.search]);

  const onBackClick = () => {
    navigate(goBackState.backUrl);
  };

  const { onTabChange, tabInUrl } = useKeepTabsInUrl({
    defaultTab: EWorkShopDetailsTabsUrl.DETAILS,
    stateInUrl: goBackState,
  });

  const tabItems: TabsProps['items'] = [
    {
      key: EWorkShopDetailsTabsUrl.DETAILS,
      label: t('details.tabs.DETAILS'),
      children: <DetailsTab data={data} isFetching={isFetching} id={id} />,
    },
    {
      key: EWorkShopDetailsTabsUrl.REGISTRANTS,
      label: t('details.tabs.REGISTRANTS'),
      children: <RegistrantsTab data={data} />,
      show: data?.status !== EWorkshopStatus.PLANNING,
    },
    {
      key: EWorkShopDetailsTabsUrl.ATTENDANCE,
      label: t('details.tabs.ATTENDANCE'),
      children: <AttendanceTab data={data} />,
      show: data?.status !== EWorkshopStatus.PLANNING,
    },
    {
      key: EWorkShopDetailsTabsUrl.WAITLIST,
      label: t('details.tabs.WAITLIST'),
      children: <WaitListTab data={data} />,
      show: data?.status !== EWorkshopStatus.PLANNING,
    },
    {
      key: EWorkShopDetailsTabsUrl.QUESTION,
      label: t('details.tabs.QUESTION'),
      children: <QuestionTab question={data?.customQuestion} workshopId={id} />,
      show: !!(
        (data?.status === EWorkshopStatus.PLANNING &&
          !data.customQuestion.allowedOperations.readonly) ||
        data?.customQuestion?.value
      ),
    },
    {
      key: EWorkShopDetailsTabsUrl.CHILD_CARE,
      label: t('details.tabs.CHILD_CARE'),
      children: <ChildCareTab id={id} />,
      show: !!(
        data?.status === EWorkshopStatus.PUBLISHED &&
        data.childcareProvided?.value
      ),
    },
    {
      key: EWorkShopDetailsTabsUrl.NOTES,
      label: t('details.tabs.NOTES'),
      children: <NotesTab workshopId={id} />,
    },
  ];

  return (
    <PageWrapper
      title={data?.host.name}
      titleSuffix={<WorkshopStatus className="ml-2" text={data?.status} />}
      {...(data && {
        backButton: {
          labelPrefix: data?.host.name,
          tooltip: goBackState.backButtonSuffix,
          onClick: onBackClick,
        },
      })}
      actions={<Actions id={id} data={data} />}
      isLoading={isLoading}
      error={error as TRtkErrorType}
    >
      <Tabs
        defaultActiveKey={tabInUrl}
        onChange={onTabChange}
        items={tabItems}
      />
    </PageWrapper>
  );
};

export default WorkShopDetails;
