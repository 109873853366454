import { useEffect, useMemo, useState } from 'react';
import { Button, Text, useTranslation } from '@packages/utils';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useGetWorkshopNotesQuery } from 'redux/apiSlice/workshops.slice';
import NoteItem from './components/NoteItem';
import AddNote from './AddNote';
import { useAppSelector } from '../../../../../../redux/store';
import { EUserType } from '../../../../../../data/types/user.types';
import CardContent from '../../../../../../components/Base/Layout/Content/CardContent';

const ADMIN_TYPES = [EUserType.READY_ADMIN, EUserType.SUPER_ADMIN];

const NotesTab = ({ workshopId }: { workshopId: string }) => {
  const usersData = useAppSelector(state => state.userSlice);

  const isAdmin = useMemo(() => {
    const user = usersData?.user;
    return user?.type?.some((t: EUserType) => ADMIN_TYPES.includes(t));
  }, [usersData?.user]);

  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.notes',
  });

  const { data } = useGetWorkshopNotesQuery(
    {
      workshopId,
    },
    {
      skip: !workshopId,
    },
  );

  const [hideForm, setHideForm] = useState<boolean>(false);

  useEffect(() => {
    if (!data) {
      return;
    }
    setHideForm(data.length > 0);
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <CardContent title={t('TITLE')} tabContent contentPadding="16px">
      {isAdmin ? (
        <>
          {hideForm ? (
            <Button
              color="primary"
              type="link"
              className="pl-0 mb-4"
              onClick={() => setHideForm(false)}
              icon={<Icon icon={EIconNames.PLUS} className="pointer" />}
            >
              {t('add.ADD_BTN')}
            </Button>
          ) : (
            <AddNote workshopId={workshopId} />
          )}
        </>
      ) : (
        <Text style={{ display: 'block', marginBottom: 8 }}>
          You can view notes on a workshop.
        </Text>
      )}
      {data.map(item => (
        <NoteItem
          key={item.noteId}
          workshopId={workshopId}
          noteId={item.noteId}
          note={item.note}
          readonly={!isAdmin}
        />
      ))}
    </CardContent>
  );
};

export default NotesTab;
