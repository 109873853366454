import {
  useTranslation,
  Col,
  useShowToastNotification,
  ENotificationType,
  TRtkErrorType,
} from '@packages/utils';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useDeleteWorkshopNoteMutation } from 'redux/apiSlice/workshops.slice';

const RemoveNote = ({
  workshopId,
  noteId,
}: {
  workshopId: string;
  noteId: string;
}) => {
  const theme = useTheme();
  const { showToastNotification } = useShowToastNotification();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.notes.delete',
  });
  const [deleteWorkshopNote, { isLoading }] = useDeleteWorkshopNoteMutation();

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    if (isLoading) {
      e.preventDefault();
      return;
    }
    try {
      await deleteWorkshopNote({
        workshopId,
        noteId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Col onClick={handleSubmit}>
      <Icon
        icon={EIconNames.DELETE_OUTLINED}
        color={theme.colorBlackIconColor}
        className="pointer"
        tooltip={{ title: t('DELETE_TOOLTIP') }}
      />
    </Col>
  );
};

export default RemoveNote;
