import {
  ENotificationType,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { useEditWorkshopNoteMutation } from 'redux/apiSlice/workshops.slice';
import NoteCard from '../NoteCard';

const NoteItem = ({
  workshopId,
  noteId,
  note,
  readonly,
}: {
  workshopId: string;
  noteId?: string;
  note?: string;
  readonly?: boolean;
}) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.notes.edit.note',
  });
  const { showToastNotification } = useShowToastNotification();

  const [editWorkshopNotes, { isLoading, isError }] =
    useEditWorkshopNoteMutation();

  const onEdit = async (value: string) => {
    try {
      await editWorkshopNotes({
        workshopId,
        noteId,
        note: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <NoteCard
      workshopId={workshopId}
      noteId={noteId}
      note={note}
      onEdit={onEdit}
      isLoading={isLoading}
      isError={isError}
      readonly={readonly}
    />
  );
};

export default NoteItem;
